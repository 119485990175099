const randomHex = require('random-hex-color')
const chroma = require('chroma-js')
const sample = require('lodash.sample')
const { randomInt } = require('../util')

const generateDiv = color => {
  color = color || randomHex()

  const mode = sample(['lab', 'lrgb', 'hsl', 'lch'])

  const sansSerif = [
    'futura, sans-serif',
    "'avenir next',avenir, sans-serif",
    "'helvetica neue', helvetica, sans-serif",
    "'century gothic', helvetica, sans-serif",
    "'century gothic', helvetica, sans-serif",
    'verdana, sans-serif',
    "'Gill Sans MT', 'Gill Sans', sans-serif",
    "'Geneva', sans-serif",
    "'Century Gothic', sans-serif",
    "'Berlin Sans FB', sans-serif",
    "'Optima', sans-serif",
    "-apple-system, BlinkMacSystemFont, -system-ui, 'avenir next', avenir, helvetica, 'helvetica neue', ubuntu, roboto, noto, 'segoe ui', arial, sans-serif"
  ]

  const serif = [
    'athelas, serif',
    "'Big caslon', sans-serif",
    'garamond, serif',
    'palatino, serif',
    'baskerville, serif',
    'georgia, serif',
    "times, 'times new roman', serif"
  ]

  const monospace = [
    'courier, monospace',
    'consolas, monospace',
    'monaco, monospace',
    '"SFMono-Regular", monospace',
    '"Menlo", monospace'
  ]

  const blue = chroma
    .hsl(randomInt(196, 220), randomInt(64, 100) / 100, randomInt(40, 64) / 100)
    .hex()

  const gray = chroma
    .hsl(randomInt(196, 240), randomInt(0, 12) / 100, randomInt(40, 60) / 100)
    .hex()
  const green = chroma
    .hsl(randomInt(80, 140), randomInt(64, 100) / 100, randomInt(40, 64) / 100)
    .hex()
  const gold = chroma
    .hsl(randomInt(24, 54), randomInt(80, 100) / 100, randomInt(45, 55) / 100)
    .hex()
  const red = chroma
    .hsl(randomInt(345, 360), randomInt(54, 100) / 100, randomInt(37, 55) / 100)
    .hex()
  const teal = chroma
    .hsl(randomInt(170, 188), randomInt(64, 100) / 100, randomInt(40, 64) / 100)
    .hex()
  const indigo = chroma
    .hsl(randomInt(250, 290), randomInt(64, 100) / 100, randomInt(40, 64) / 100)
    .hex()
  const violet = chroma
    .hsl(randomInt(290, 330), randomInt(64, 100) / 100, randomInt(40, 64) / 100)
    .hex()

  const typescales = [
    [600, 500, 300, 225, 150, 125, 100, 87.5, 75],
    [1200, 800, 600, 400, 300, 200, 150, 100, 75],
    [900, 700, 500, 400, 300, 200, 150, 100, 75]
  ]

  const typescale = sample(typescales)

  const div = {
    canvasBg: '#ffffff',
    fontSizeHeadline: typescale[0],
    fontSizeSubheadline: typescale[1],
    fontSize1: typescale[2],
    fontSize2: typescale[3],
    fontSize3: typescale[4],
    fontSize4: typescale[5],
    fontSize5: typescale[6],
    fontSize6: typescale[7],
    fontSize7: typescale[8],
    spacing1: 25,
    spacing2: 50,
    spacing3: 100,
    spacing4: 200,
    spacing5: 400,
    spacing6: 800,
    spacing7: 1600,
    lineHeightSolid: 100,
    lineHeightTitle: 125,
    lineHeightCopy: 150,
    boxShadow1: '0px 0px 4px 2px rgba( 0, 0, 0, 0.2 )',
    boxShadow2: '0px 0px 8px 2px rgba( 0, 0, 0, 0.2 )',
    boxShadow3: '2px 2px 4px 2px rgba( 0, 0, 0, 0.2 )',
    boxShadow4: '2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )',
    boxShadow5: '4px 4px 8px 0px rgba( 0, 0, 0, 0.2 )',
    borderRadius1: 12.5,
    borderRadius2: 25,
    borderRadius3: 50,
    borderRadius4: 100,
    borderRadiusCircle: '100%',
    borderRadiusPill: '9999px',
    letterSpacingTight: '-.05em',
    letterSpacing1: '.1em',
    letterSpacing2: '.25em',
    measure: '30em',
    measureNarrow: '20em',
    measureWide: '34em',
    sansSerif: sample(sansSerif),
    serif: sample(serif),
    monospace: sample(monospace),
    previewTypeface: sample(monospace),
    blue: blue,
    indigo: indigo,
    violet: violet,
    green: green,
    red: red,
    gold: gold,
    teal: teal,
    gray: gray,
    mode: mode
  }

  return {
    ...div
  }
}

module.exports = generateDiv
