import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import chroma from 'chroma-js'

import { Grid, ArrowRight, Heart, BookOpen } from 'react-feather'
import { randomInt } from '../lib/util'

import Div from '../elements/Div'
import Code from '../elements/Code'
import H4 from '../elements/H4'
import P from '../elements/P'
import H5 from '../elements/H5'
import Textarea from '../elements/Textarea'
import Input from '../elements/Input'
import Span from '../elements/Span'
import Flex from '../components/Flex'
import SectionBorder from '../components/SectionBorder'
import Lock from '../components/Lock'
import Layout from '../components/VoteLayout'
import Label from '../components/Label'
import Color from '../components/controls/Color'
import Hidden from '../components/hidden'

import VoteButton from '../components/vote-button'
import SidebarHeading from '../components/heading-sidebar'

import randomDiv from '../lib/generate/tachyons-theme'
import { db } from '../lib/client'
import { UP_KEYCODE, RIGHT_KEYCODE } from '../lib/constants'

const Main = ({ div }) => {
  const mode = div.mode

  const blueScale = chroma
    .scale(['#000000', div.blue, '#ffffff'])
    .mode(div.mode)
    .correctLightness()
    .colors(12)
    .slice(1, -1)
  const indigoScale = chroma
    .scale(['#000000', div.indigo, '#ffffff'])
    .mode(div.mode)
    .correctLightness()
    .colors(12)
    .slice(1, -1)
  const violetScale = chroma
    .scale(['#000000', div.violet, '#ffffff'])
    .mode(div.mode)
    .correctLightness()
    .colors(12)
    .slice(1, -1)
  const redScale = chroma
    .scale(['#000000', div.red, '#ffffff'])
    .mode(div.mode)
    .correctLightness()
    .colors(12)
    .slice(1, -1)
  const goldScale = chroma
    .scale(['#000000', div.gold, '#ffffff'])
    .mode(div.mode)
    .correctLightness()
    .colors(12)
    .slice(1, -1)
  const greenScale = chroma
    .scale(['#000000', div.green, '#ffffff'])
    .padding([0.05, -0.25])
    .mode(div.mode)
    .correctLightness()
    .colors(12)
    .slice(1, -1)
  const tealScale = chroma
    .scale(['#000000', div.teal, '#ffffff'])
    .padding([0.05, 0.1])
    .mode(div.mode)
    .correctLightness()
    .colors(12)
    .slice(1, -1)
  const grayScale = chroma
    .scale(['#000000', div.gray, '#ffffff'])
    .padding([0, -5.5])
    .mode(div.mode)
    .correctLightness()
    .colors(12)
    .slice(1, -1)

  return (
    <div
      css={{
        minHeight: '16rem',
        height: '100%',
        //maxHeight: '100vh',
        overflowY: 'scroll',
        paddingTop: 64,
        paddingLeft: 64,
        paddingRight: 64,
        background: div.canvasBg,
        color:
          chroma.contrast(div.canvasBg, 'white') > 4.4
            ? grayScale[9]
            : grayScale[0]
      }}
    >
      <div>
        <link
          rel="stylesheet"
          href="https://components-ai.s3.us-east-1.amazonaws.com/assets/tachyons-custom.min.css"
        />
        <style>
          {`:root {` +
            `--font-size-headline: ${div.fontSizeHeadline / 100}rem;\n` +
            `--font-size-subheadline: ${div.fontSizeSubheadline / 100}rem;\n` +
            `--font-size-1: ${div.fontSize1 / 100}rem;\n` +
            `--font-size-2: ${div.fontSize2 / 100}rem;\n` +
            `--font-size-3: ${div.fontSize3 / 100}rem;\n` +
            `--font-size-4: ${div.fontSize4 / 100}rem;\n` +
            `--font-size-5: ${div.fontSize5 / 100}rem;\n` +
            `--font-size-6: ${div.fontSize6 / 100}rem;\n` +
            `--font-size-7: ${div.fontSize7 / 100}rem;\n` +
            `--spacing-extra-small: ${div.spacing1 / 100}rem;\n` +
            `--spacing-small: ${div.spacing2 / 100}rem;\n` +
            `--spacing-medium: ${div.spacing3 / 100}rem;\n` +
            `--spacing-large: ${div.spacing4 / 100}rem;\n` +
            `--spacing-extra-large: ${div.spacing5 / 100}rem;\n` +
            `--spacing-extra-extra-large: ${div.spacing6 / 100}rem;\n` +
            `--spacing-extra-extra-extra-large: ${div.spacing7 / 100}rem;\n` +
            `--line-height-solid: ${div.lineHeightSolid / 100};\n` +
            `--line-height-title: ${div.lineHeightTitle / 100};\n` +
            `--line-height-copy: ${div.lineHeightCopy / 100};\n` +
            `--sans-serif: ${div.sansSerif};\n` +
            `--serif: ${div.serif};\n` +
            `--monospace: ${div.monospace};\n` +
            `--border-radius-none: 0;\n` +
            `--border-radius-1: ${div.borderRadius1 / 100}rem;\n` +
            `--border-radius-2: ${div.borderRadius2 / 100}rem;\n` +
            `--border-radius-3: ${div.borderRadius3 / 100}rem;\n` +
            `--border-radius-4: ${div.borderRadius4 / 100}rem;\n` +
            `--border-radius-circle: 100%;\n` +
            `--border-radius-pill: 9999px;\n` +
            `--box-shadow-1: ${div.boxShadow1};\n` +
            `--box-shadow-2: ${div.boxShadow2};\n` +
            `--box-shadow-3: ${div.boxShadow3};\n` +
            `--box-shadow-4: ${div.boxShadow4};\n` +
            `--box-shadow-5: ${div.boxShadow5};\n` +
            `--red-000: ${redScale[0]};\n` +
            `--red-100: ${redScale[1]};\n` +
            `--red-200: ${redScale[2]};\n` +
            `--red-300: ${redScale[3]};\n` +
            `--red-400: ${redScale[4]};\n` +
            `--red-500: ${redScale[5]};\n` +
            `--red-600: ${redScale[6]};\n` +
            `--red-700: ${redScale[7]};\n` +
            `--red-800: ${redScale[8]};\n` +
            `--red-900: ${redScale[9]};\n` +
            `--gold-000: ${goldScale[0]};\n` +
            `--gold-100: ${goldScale[1]};\n` +
            `--gold-200: ${goldScale[2]};\n` +
            `--gold-300: ${goldScale[3]};\n` +
            `--gold-400: ${goldScale[4]};\n` +
            `--gold-500: ${goldScale[5]};\n` +
            `--gold-600: ${goldScale[6]};\n` +
            `--gold-700: ${goldScale[7]};\n` +
            `--gold-800: ${goldScale[8]};\n` +
            `--gold-900: ${goldScale[9]};\n` +
            `--green-000: ${greenScale[0]};\n` +
            `--green-100: ${greenScale[1]};\n` +
            `--green-200: ${greenScale[2]};\n` +
            `--green-300: ${greenScale[3]};\n` +
            `--green-400: ${greenScale[4]};\n` +
            `--green-500: ${greenScale[5]};\n` +
            `--green-600: ${greenScale[6]};\n` +
            `--green-700: ${greenScale[7]};\n` +
            `--green-800: ${greenScale[8]};\n` +
            `--green-900: ${greenScale[9]};\n` +
            `--teal-000: ${tealScale[0]};\n` +
            `--teal-100: ${tealScale[1]};\n` +
            `--teal-200: ${tealScale[2]};\n` +
            `--teal-300: ${tealScale[3]};\n` +
            `--teal-400: ${tealScale[4]};\n` +
            `--teal-500: ${tealScale[5]};\n` +
            `--teal-600: ${tealScale[6]};\n` +
            `--teal-700: ${tealScale[7]};\n` +
            `--teal-800: ${tealScale[8]};\n` +
            `--teal-900: ${tealScale[9]};\n` +
            `--blue-000: ${blueScale[0]};\n` +
            `--blue-100: ${blueScale[1]};\n` +
            `--blue-200: ${blueScale[2]};\n` +
            `--blue-300: ${blueScale[3]};\n` +
            `--blue-400: ${blueScale[4]};\n` +
            `--blue-500: ${blueScale[5]};\n` +
            `--blue-600: ${blueScale[6]};\n` +
            `--blue-700: ${blueScale[7]};\n` +
            `--blue-800: ${blueScale[8]};\n` +
            `--blue-900: ${blueScale[9]};\n` +
            `--indigo-000: ${indigoScale[0]};\n` +
            `--indigo-100: ${indigoScale[1]};\n` +
            `--indigo-200: ${indigoScale[2]};\n` +
            `--indigo-300: ${indigoScale[3]};\n` +
            `--indigo-400: ${indigoScale[4]};\n` +
            `--indigo-500: ${indigoScale[5]};\n` +
            `--indigo-600: ${indigoScale[6]};\n` +
            `--indigo-700: ${indigoScale[7]};\n` +
            `--indigo-800: ${indigoScale[8]};\n` +
            `--indigo-900: ${indigoScale[9]};\n` +
            `--violet-000: ${violetScale[0]};\n` +
            `--violet-100: ${violetScale[1]};\n` +
            `--violet-200: ${violetScale[2]};\n` +
            `--violet-300: ${violetScale[3]};\n` +
            `--violet-400: ${violetScale[4]};\n` +
            `--violet-500: ${violetScale[5]};\n` +
            `--violet-600: ${violetScale[6]};\n` +
            `--violet-700: ${violetScale[7]};\n` +
            `--violet-800: ${violetScale[8]};\n` +
            `--violet-900: ${violetScale[9]};\n` +
            `--gray-000: ${grayScale[0]};\n` +
            `--gray-100: ${grayScale[1]};\n` +
            `--gray-200: ${grayScale[2]};\n` +
            `--gray-300: ${grayScale[3]};\n` +
            `--gray-400: ${grayScale[4]};\n` +
            `--gray-500: ${grayScale[5]};\n` +
            `--gray-600: ${grayScale[6]};\n` +
            `--gray-700: ${grayScale[7]};\n` +
            `--gray-800: ${grayScale[8]};\n` +
            `--gray-900: ${grayScale[9]};\n` +
            '}'}
        </style>

        <div css={{ width: '100%' }}>
          <section css={{ display: 'flex', fontFamily: div.sansSerif }}>
            <div css={{ width: '50%', paddingRight: 16 }}>
              <div
                css={{
                  backgroundColor: greenScale[4],
                  color: greenScale[9],
                  borderColor: greenScale[3],
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: div.borderRadius2 / 100 + 'rem',
                  paddingTop: div.spacing3 / 100 + 'rem',
                  paddingBottom: div.spacing3 / 100 + 'rem',
                  paddingLeft: div.spacing3 / 100 + 'rem',
                  paddingRight: div.spacing3 / 100 + 'rem',
                  fontSize: div.fontSize6 / 100 + 'rem',
                  display: 'flex',
                  marginBottom: 8,
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span>
                  An example of an <b>alert component</b>
                </span>
                <svg
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  css={{ height: 16, width: 16 }}
                >
                  <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                </svg>
              </div>
              <div
                css={{
                  backgroundColor: tealScale[4],
                  color: tealScale[9],
                  borderColor: tealScale[3],
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: div.borderRadius2 / 100 + 'rem',
                  paddingTop: div.spacing3 / 100 + 'rem',
                  paddingBottom: div.spacing3 / 100 + 'rem',
                  paddingLeft: div.spacing3 / 100 + 'rem',
                  paddingRight: div.spacing3 / 100 + 'rem',
                  fontSize: div.fontSize6 / 100 + 'rem',
                  marginBottom: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span>
                  An example of an <b>alert component</b>
                </span>
                <svg
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  css={{ height: 16, width: 16 }}
                >
                  <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                </svg>
              </div>
              <div
                css={{
                  backgroundColor: blueScale[4],
                  color: blueScale[9],
                  borderColor: blueScale[3],
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: div.borderRadius2 / 100 + 'rem',
                  paddingTop: div.spacing3 / 100 + 'rem',
                  paddingBottom: div.spacing3 / 100 + 'rem',
                  paddingLeft: div.spacing3 / 100 + 'rem',
                  paddingRight: div.spacing3 / 100 + 'rem',
                  fontSize: div.fontSize6 / 100 + 'rem',
                  marginBottom: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span>
                  An example of an <b>alert component</b>
                </span>
                <svg
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  css={{ height: 16, width: 16 }}
                >
                  <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                </svg>
              </div>
              <div
                css={{
                  backgroundColor: indigoScale[4],
                  color: indigoScale[9],
                  borderColor: indigoScale[3],
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: div.borderRadius2 / 100 + 'rem',
                  paddingTop: div.spacing3 / 100 + 'rem',
                  paddingBottom: div.spacing3 / 100 + 'rem',
                  paddingLeft: div.spacing3 / 100 + 'rem',
                  paddingRight: div.spacing3 / 100 + 'rem',
                  fontSize: div.fontSize6 / 100 + 'rem',
                  marginBottom: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span>
                  An example of an <b>alert component</b>
                </span>
                <svg
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  css={{ height: 16, width: 16 }}
                >
                  <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                </svg>
              </div>
              <div
                css={{
                  backgroundColor: violetScale[4],
                  color: violetScale[9],
                  borderColor: violetScale[3],
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: div.borderRadius2 / 100 + 'rem',
                  paddingTop: div.spacing3 / 100 + 'rem',
                  paddingBottom: div.spacing3 / 100 + 'rem',
                  paddingLeft: div.spacing3 / 100 + 'rem',
                  paddingRight: div.spacing3 / 100 + 'rem',
                  fontSize: div.fontSize6 / 100 + 'rem',
                  marginBottom: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span>
                  An example of an <b>alert component</b>
                </span>
                <svg
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  css={{ height: 16, width: 16 }}
                >
                  <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                </svg>
              </div>
              <div
                css={{
                  backgroundColor: redScale[4],
                  color: redScale[9],
                  borderColor: redScale[3],
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: div.borderRadius2 / 100 + 'rem',
                  paddingTop: div.spacing3 / 100 + 'rem',
                  paddingBottom: div.spacing3 / 100 + 'rem',
                  paddingLeft: div.spacing3 / 100 + 'rem',
                  paddingRight: div.spacing3 / 100 + 'rem',
                  fontSize: div.fontSize6 / 100 + 'rem',
                  marginBottom: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span>
                  An example of an <b>alert component</b>
                </span>
                <svg
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  css={{ height: 16, width: 16 }}
                >
                  <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                </svg>
              </div>
              <div
                css={{
                  backgroundColor: grayScale[2],
                  color: grayScale[9],
                  borderColor: grayScale[1],
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: div.borderRadius2 / 100 + 'rem',
                  paddingTop: div.spacing3 / 100 + 'rem',
                  paddingBottom: div.spacing3 / 100 + 'rem',
                  paddingLeft: div.spacing3 / 100 + 'rem',
                  paddingRight: div.spacing3 / 100 + 'rem',
                  fontSize: div.fontSize6 / 100 + 'rem',
                  marginBottom: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span>
                  An example of an <b>alert component</b>
                </span>
                <svg
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  css={{ height: 16, width: 16 }}
                >
                  <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                </svg>
              </div>
            </div>
            <div css={{ width: '50%', paddingLeft: 16 }}>
              <div
                css={{
                  backgroundColor: greenScale[9],
                  color: greenScale[2],
                  borderColor: greenScale[8],
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: div.borderRadius2 / 100 + 'rem',
                  paddingTop: div.spacing3 / 100 + 'rem',
                  paddingBottom: div.spacing3 / 100 + 'rem',
                  paddingLeft: div.spacing3 / 100 + 'rem',
                  paddingRight: div.spacing3 / 100 + 'rem',
                  fontSize: div.fontSize6 / 100 + 'rem',
                  marginBottom: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span>
                  An example of an <b>alert component</b>
                </span>
                <svg
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  css={{ height: 16, width: 16 }}
                >
                  <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                </svg>
              </div>
              <div
                css={{
                  backgroundColor: tealScale[9],
                  color: tealScale[2],
                  borderColor: tealScale[8],
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: div.borderRadius2 / 100 + 'rem',
                  paddingTop: div.spacing3 / 100 + 'rem',
                  paddingBottom: div.spacing3 / 100 + 'rem',
                  paddingLeft: div.spacing3 / 100 + 'rem',
                  paddingRight: div.spacing3 / 100 + 'rem',
                  fontSize: div.fontSize6 / 100 + 'rem',
                  marginBottom: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span>
                  An example of an <b>alert component</b>
                </span>
                <svg
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  css={{ height: 16, width: 16 }}
                >
                  <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                </svg>
              </div>
              <div
                css={{
                  backgroundColor: blueScale[9],
                  color: blueScale[3],
                  borderColor: blueScale[8],
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: div.borderRadius2 / 100 + 'rem',
                  paddingTop: div.spacing3 / 100 + 'rem',
                  paddingBottom: div.spacing3 / 100 + 'rem',
                  paddingLeft: div.spacing3 / 100 + 'rem',
                  paddingRight: div.spacing3 / 100 + 'rem',
                  fontSize: div.fontSize6 / 100 + 'rem',
                  marginBottom: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span>
                  An example of an <b>alert component</b>
                </span>
                <svg
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  css={{ height: 16, width: 16 }}
                >
                  <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                </svg>
              </div>
              <div
                css={{
                  backgroundColor: indigoScale[9],
                  color: indigoScale[3],
                  borderColor: indigoScale[8],
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: div.borderRadius2 / 100 + 'rem',
                  paddingTop: div.spacing3 / 100 + 'rem',
                  paddingBottom: div.spacing3 / 100 + 'rem',
                  paddingLeft: div.spacing3 / 100 + 'rem',
                  paddingRight: div.spacing3 / 100 + 'rem',
                  fontSize: div.fontSize6 / 100 + 'rem',
                  marginBottom: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span>
                  An example of an <b>alert component</b>
                </span>
                <svg
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  css={{ height: 16, width: 16 }}
                >
                  <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                </svg>
              </div>
              <div
                css={{
                  backgroundColor: violetScale[9],
                  color: violetScale[3],
                  borderColor: violetScale[8],
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: div.borderRadius2 / 100 + 'rem',
                  paddingTop: div.spacing3 / 100 + 'rem',
                  paddingBottom: div.spacing3 / 100 + 'rem',
                  paddingLeft: div.spacing3 / 100 + 'rem',
                  paddingRight: div.spacing3 / 100 + 'rem',
                  fontSize: div.fontSize6 / 100 + 'rem',
                  marginBottom: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span>
                  An example of an <b>alert component</b>
                </span>
                <svg
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  css={{ height: 16, width: 16 }}
                >
                  <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                </svg>
              </div>
              <div
                css={{
                  backgroundColor: redScale[9],
                  color: redScale[3],
                  borderColor: redScale[8],
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: div.borderRadius2 / 100 + 'rem',
                  paddingTop: div.spacing3 / 100 + 'rem',
                  paddingBottom: div.spacing3 / 100 + 'rem',
                  paddingLeft: div.spacing3 / 100 + 'rem',
                  paddingRight: div.spacing3 / 100 + 'rem',
                  fontSize: div.fontSize6 / 100 + 'rem',
                  marginBottom: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span>
                  An example of an <b>alert component</b>
                </span>
                <svg
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  css={{ height: 16, width: 16 }}
                >
                  <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                </svg>
              </div>
              <div
                css={{
                  backgroundColor: grayScale[9],
                  color: grayScale[2],
                  borderColor: grayScale[8],
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: div.borderRadius2 / 100 + 'rem',
                  paddingTop: div.spacing3 / 100 + 'rem',
                  paddingBottom: div.spacing3 / 100 + 'rem',
                  paddingLeft: div.spacing3 / 100 + 'rem',
                  paddingRight: div.spacing3 / 100 + 'rem',
                  fontSize: div.fontSize6 / 100 + 'rem',
                  marginBottom: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span>
                  An example of an <b>alert component</b>
                </span>
                <svg
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  css={{ height: 16, width: 16 }}
                >
                  <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                </svg>
              </div>
            </div>
          </section>
          <section css={{ display: 'flex', fontFamily: div.sansSerif }}>
            <div
              css={{
                width: '50%',
                display: 'block',
                paddingRight: 16,
                paddingTop: 16
              }}
            >
              <section>
                <div
                  css={{
                    backgroundColor: greenScale[4],
                    color: greenScale[9],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: tealScale[4],
                    color: tealScale[9],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: blueScale[4],
                    color: blueScale[9],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: indigoScale[4],
                    color: indigoScale[9],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: violetScale[4],
                    color: violetScale[9],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: redScale[4],
                    color: redScale[9],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: grayScale[2],
                    color: grayScale[9],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
              </section>
              <section>
                <div
                  css={{
                    backgroundColor: greenScale[4],
                    color: greenScale[9],
                    borderRadius: div.borderRadius4 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: tealScale[4],
                    color: tealScale[9],
                    borderRadius: div.borderRadius4 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: blueScale[4],
                    color: blueScale[9],
                    borderRadius: div.borderRadius4 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: indigoScale[4],
                    color: indigoScale[9],
                    borderRadius: div.borderRadius4 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: violetScale[4],
                    color: violetScale[9],
                    borderRadius: div.borderRadius4 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: redScale[4],
                    color: redScale[9],
                    borderRadius: div.borderRadius4 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: grayScale[2],
                    color: grayScale[9],
                    borderRadius: div.borderRadius4 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
              </section>
              <section name="buttons" css={{ display: 'flex' }}>
                <button
                  css={{
                    backgroundColor: greenScale[4],
                    color: greenScale[9],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '0px solid transparent',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M1 14 L5 10 L13 18 L27 4 L31 8 L13 26 z" />
                  </svg>
                  <span css={{ marginLeft: 4 }}>Click</span>
                </button>
                <button
                  css={{
                    backgroundColor: tealScale[4],
                    color: tealScale[9],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '0px solid transparent',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M16 0 L32 16 L28 16 L28 30 L20 30 L20 20 L12 20 L12 30 L4 30 L4 16 L0 16 Z" />
                  </svg>
                  <span css={{ marginLeft: 4 }}>Click</span>
                </button>
                <button
                  css={{
                    backgroundColor: blueScale[4],
                    color: blueScale[9],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '0px solid transparent',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M12 0 A12 12 0 0 0 0 12 A12 12 0 0 0 12 24 A12 12 0 0 0 18.5 22.25 L28 32 L32 28 L22.25 18.5 A12 12 0 0 0 24 12 A12 12 0 0 0 12 0 M12 4 A8 8 0 0 1 12 20 A8 8 0 0 1 12 4" />
                  </svg>
                  <span css={{ marginLeft: 4 }}>Click</span>
                </button>
                <button
                  css={{
                    backgroundColor: indigoScale[4],
                    color: indigoScale[9],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '0px solid transparent',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M32 16 A16 12 0 0 0 0 16 A16 12 0 0 0 16 28 L18 28 C20 30 24 32 28 32 C27 31 26 28 26 25.375 L26 25.375 A16 12 0 0 0 32 16" />
                  </svg>
                  <span css={{ marginLeft: 4 }}>Click</span>
                </button>
                <button
                  css={{
                    backgroundColor: violetScale[4],
                    color: violetScale[9],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '0px solid transparent',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M0 18 L16 2 L29 3 L30 16 L14 32 z M20 9 A3 3 0 0 0 26 9 A3 3 0 0 0 20 9" />
                  </svg>
                  <span css={{ marginLeft: 4 }}>Click</span>
                </button>
                <button
                  css={{
                    backgroundColor: redScale[4],
                    color: redScale[9],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '0px solid transparent',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M4 4 L10 4 L12 2 L20 2 L22 4 L28 4 L29 8 L3 8 z M5 10 L27 10 L26 30 L6 30 z" />
                  </svg>
                  <span css={{ marginLeft: 4, verticalAlign: 'middle' }}>
                    Click
                  </span>
                </button>
                <button
                  css={{
                    backgroundColor: grayScale[9],
                    color: grayScale[2],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '0px solid transparent',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                  </svg>
                  <span css={{ verticalAlign: 'middle', marginLeft: 4 }}>
                    Click
                  </span>
                </button>
              </section>
              <section name="buttons" css={{ display: 'flex' }}>
                <button
                  css={{
                    color: greenScale[4],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '2px solid currentColor',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M1 14 L5 10 L13 18 L27 4 L31 8 L13 26 z" />
                  </svg>
                  <span css={{ marginLeft: 4 }}>Click</span>
                </button>
                <button
                  css={{
                    color: tealScale[4],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '2px solid currentColor',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M16 0 L32 16 L28 16 L28 30 L20 30 L20 20 L12 20 L12 30 L4 30 L4 16 L0 16 Z" />
                  </svg>
                  <span css={{ marginLeft: 4 }}>Click</span>
                </button>
                <button
                  css={{
                    color: blueScale[4],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '2px solid currentColor',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M12 0 A12 12 0 0 0 0 12 A12 12 0 0 0 12 24 A12 12 0 0 0 18.5 22.25 L28 32 L32 28 L22.25 18.5 A12 12 0 0 0 24 12 A12 12 0 0 0 12 0 M12 4 A8 8 0 0 1 12 20 A8 8 0 0 1 12 4" />
                  </svg>
                  <span css={{ marginLeft: 4 }}>Click</span>
                </button>
                <button
                  css={{
                    color: indigoScale[4],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '2px solid currentColor',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M32 16 A16 12 0 0 0 0 16 A16 12 0 0 0 16 28 L18 28 C20 30 24 32 28 32 C27 31 26 28 26 25.375 L26 25.375 A16 12 0 0 0 32 16" />
                  </svg>
                  <span css={{ marginLeft: 4 }}>Click</span>
                </button>
                <button
                  css={{
                    color: violetScale[4],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '2px solid currentColor',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M0 18 L16 2 L29 3 L30 16 L14 32 z M20 9 A3 3 0 0 0 26 9 A3 3 0 0 0 20 9" />
                  </svg>
                  <span css={{ marginLeft: 4 }}>Click</span>
                </button>
                <button
                  css={{
                    color: redScale[4],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '2px solid currentColor',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M4 4 L10 4 L12 2 L20 2 L22 4 L28 4 L29 8 L3 8 z M5 10 L27 10 L26 30 L6 30 z" />
                  </svg>
                  <span css={{ marginLeft: 4, verticalAlign: 'middle' }}>
                    Click
                  </span>
                </button>
                <button
                  css={{
                    color: grayScale[4],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '2px solid currentColor',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                  </svg>
                  <span css={{ verticalAlign: 'middle', marginLeft: 4 }}>
                    Click
                  </span>
                </button>
              </section>
              <section>
                <article></article>
              </section>
            </div>
            <div
              css={{
                width: '50%',
                display: 'block',
                paddingLeft: 16,
                paddingTop: 16
              }}
            >
              <section>
                <div
                  css={{
                    backgroundColor: greenScale[9],
                    color: greenScale[3],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: tealScale[9],
                    color: tealScale[3],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: blueScale[9],
                    color: blueScale[3],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: indigoScale[9],
                    color: indigoScale[3],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: violetScale[9],
                    color: violetScale[3],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: redScale[9],
                    color: redScale[3],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: grayScale[9],
                    color: grayScale[3],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
              </section>
              <section>
                <div
                  css={{
                    backgroundColor: greenScale[9],
                    color: greenScale[3],
                    borderRadius: div.borderRadius4 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: tealScale[9],
                    color: tealScale[3],
                    borderRadius: div.borderRadius4 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: blueScale[9],
                    color: blueScale[3],
                    borderRadius: div.borderRadius4 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: indigoScale[9],
                    color: indigoScale[3],
                    borderRadius: div.borderRadius4 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: violetScale[9],
                    color: violetScale[3],
                    borderRadius: div.borderRadius4 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: redScale[9],
                    color: redScale[3],
                    borderRadius: div.borderRadius4 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
                <div
                  css={{
                    backgroundColor: grayScale[9],
                    color: grayScale[3],
                    borderRadius: div.borderRadius4 / 100 + 'rem',
                    paddingTop: div.spacing1 / 100 + 'rem',
                    paddingBottom: div.spacing1 / 100 + 'rem',
                    paddingLeft: div.spacing2 / 100 + 'rem',
                    paddingRight: div.spacing2 / 100 + 'rem',
                    fontSize: div.fontSize7 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'inline-block',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Badge</span>
                </div>
              </section>
              <section
                name="buttons"
                className="sans-serif"
                style={{ display: 'flex' }}
              >
                <button
                  css={{
                    backgroundColor: greenScale[9],
                    color: greenScale[3],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '0px solid transparent',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M1 14 L5 10 L13 18 L27 4 L31 8 L13 26 z" />
                  </svg>
                  <span css={{ marginLeft: 4 }}>Click</span>
                </button>
                <button
                  css={{
                    backgroundColor: tealScale[9],
                    color: tealScale[3],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '0px solid transparent',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M16 0 L32 16 L28 16 L28 30 L20 30 L20 20 L12 20 L12 30 L4 30 L4 16 L0 16 Z" />
                  </svg>
                  <span css={{ marginLeft: 4 }}>Click</span>
                </button>
                <button
                  css={{
                    backgroundColor: blueScale[9],
                    color: blueScale[3],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '0px solid transparent',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M12 0 A12 12 0 0 0 0 12 A12 12 0 0 0 12 24 A12 12 0 0 0 18.5 22.25 L28 32 L32 28 L22.25 18.5 A12 12 0 0 0 24 12 A12 12 0 0 0 12 0 M12 4 A8 8 0 0 1 12 20 A8 8 0 0 1 12 4" />
                  </svg>
                  <span css={{ marginLeft: 4 }}>Click</span>
                </button>
                <button
                  css={{
                    backgroundColor: indigoScale[9],
                    color: indigoScale[3],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '0px solid transparent',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M32 16 A16 12 0 0 0 0 16 A16 12 0 0 0 16 28 L18 28 C20 30 24 32 28 32 C27 31 26 28 26 25.375 L26 25.375 A16 12 0 0 0 32 16" />
                  </svg>
                  <span css={{ marginLeft: 4 }}>Click</span>
                </button>
                <button
                  css={{
                    backgroundColor: violetScale[9],
                    color: violetScale[3],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '0px solid transparent',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M0 18 L16 2 L29 3 L30 16 L14 32 z M20 9 A3 3 0 0 0 26 9 A3 3 0 0 0 20 9" />
                  </svg>
                  <span css={{ marginLeft: 4 }}>Click</span>
                </button>
                <button
                  css={{
                    backgroundColor: redScale[9],
                    color: redScale[3],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '0px solid transparent',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M4 4 L10 4 L12 2 L20 2 L22 4 L28 4 L29 8 L3 8 z M5 10 L27 10 L26 30 L6 30 z" />
                  </svg>
                  <span css={{ marginLeft: 4, verticalAlign: 'middle' }}>
                    Click
                  </span>
                </button>
                <button
                  css={{
                    backgroundColor: grayScale[9],
                    color: grayScale[3],
                    borderRadius: div.borderRadius2 / 100 + 'rem',
                    border: '0px solid transparent',
                    paddingTop: div.spacing2 / 100 + 'rem',
                    paddingBottom: div.spacing2 / 100 + 'rem',
                    paddingLeft: div.spacing3 / 100 + 'rem',
                    paddingRight: div.spacing3 / 100 + 'rem',
                    fontSize: div.fontSize6 / 100 + 'rem',
                    fontWeight: 500,
                    display: 'flex',
                    marginBottom: 8,
                    marginRight: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="12"
                    height="12"
                    fill="currentColor"
                  >
                    <path d="M4 8 L8 4 L16 12 L24 4 L28 8 L20 16 L28 24 L24 28 L16 20 L8 28 L4 24 L12 16 z" />
                  </svg>
                  <span css={{ verticalAlign: 'middle', marginLeft: 4 }}>
                    Click
                  </span>
                </button>
              </section>
            </div>
          </section>
          <section css={{ marginTop: 64 }}>
            <div css={{ display: 'flex' }}>
              <article css={{ paddingRight: 16 }}>
                <img
                  css={{
                    display: 'block',
                    mixBlendMode: 'multiply',
                    borderRadius: div.borderRadius1 / 100 + 'rem'
                  }}
                  src="http://dc28c2r6oodom.cloudfront.net/p/h/175.jpg"
                />
              </article>
              <article css={{ paddingRight: 12, paddingLeft: 8 }}>
                <img
                  css={{
                    display: 'block',
                    borderRadius: div.borderRadius2 / 100 + 'rem'
                  }}
                  src="http://dc28c2r6oodom.cloudfront.net/p/h/175.jpg"
                />
              </article>
              <article css={{ paddingLeft: 12, paddingRight: 8 }}>
                <img
                  css={{
                    display: 'block',
                    borderRadius: div.borderRadius3 / 100 + 'rem'
                  }}
                  src="http://dc28c2r6oodom.cloudfront.net/p/h/175.jpg"
                />
              </article>
              <article css={{ paddingLeft: 16 }}>
                <img
                  css={{
                    display: 'block',
                    borderRadius: div.borderRadius4 / 100 + 'rem'
                  }}
                  src="http://dc28c2r6oodom.cloudfront.net/p/h/175.jpg"
                />
              </article>
            </div>
          </section>
          <section
            css={{ display: 'flex', marginTop: 16 }}
            className="sans-serif"
          >
            <div css={{ paddingRight: 16, width: '33.33%' }}>
              <article
                css={{
                  overflow: 'hidden',
                  color: grayScale[2],
                  borderRadius: div.borderRadius2 / 100 + 'rem',
                  border: '1px solid currentColor'
                }}
              >
                <img
                  css={{ display: 'block' }}
                  src="http://dc28c2r6oodom.cloudfront.net/p/h/168.jpg"
                />
                <div css={{ padding: 16 }}>
                  <h4
                    css={{
                      marginTop: 0,
                      marginBottom: 8,
                      fontSize: div.fontSize5 / 100 + 'rem'
                    }}
                  >
                    Card Title
                  </h4>
                  <p
                    css={{
                      lineHeight: div.lineHeightCopy / 100,
                      fontSize: div.fontSize6 / 100 + 'rem',
                      marginBottom: 16,
                      marginTop: 0
                    }}
                  >
                    At vero eos et accusam et justo duo dolores et ea rebum.
                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                    ipsum dolor sit amet.
                  </p>
                  <a
                    href="#0"
                    css={{
                      background: blueScale[4],
                      color: '#fff',
                      borderRadius: div.borderRadius2 / 100 + 'rem',
                      display: 'inline-block',
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 12,
                      textDecoration: 'none'
                    }}
                  >
                    Read More
                  </a>
                </div>
              </article>
            </div>
            <div css={{ paddingRight: 8, paddingLeft: 8, width: '33.33%' }}>
              <article
                css={{
                  overflow: 'hidden',
                  color: grayScale[2],
                  borderRadius: div.borderRadius3 / 100 + 'rem',
                  border: '1px solid ' + grayScale[5]
                }}
              >
                <img
                  css={{ display: 'block' }}
                  src="http://dc28c2r6oodom.cloudfront.net/p/h/169.jpg"
                />
                <div css={{ padding: 16 }}>
                  <h4
                    css={{
                      marginTop: 0,
                      marginBottom: 8,
                      fontSize: div.fontSize5 / 100 + 'rem'
                    }}
                  >
                    Card Title
                  </h4>
                  <p
                    css={{
                      lineHeight: div.lineHeightCopy / 100,
                      fontSize: div.fontSize6 / 100 + 'rem',
                      marginBottom: 16,
                      marginTop: 0
                    }}
                  >
                    Tempor invidunt ut labore et dolore magna aliquyam erat, sed
                    diam voluptua. At vero eos et accusam et justo duo dolores
                    et ea rebum.
                  </p>
                  <a
                    href="#0"
                    css={{
                      background: violetScale[4],
                      color: 'white',
                      borderRadius: div.borderRadius3 / 100 + 'rem',
                      display: 'inline-block',
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 12,
                      textDecoration: 'none'
                    }}
                  >
                    Read More
                  </a>
                </div>
              </article>
            </div>
            <div css={{ width: '33.33%', paddingLeft: 16 }}>
              <article
                css={{
                  overflow: 'hidden',
                  color: grayScale[2],
                  borderRadius: div.borderRadius4 / 100 + 'rem',
                  border: '1px solid ' + grayScale[9]
                }}
              >
                <img
                  css={{ display: 'block' }}
                  src="http://dc28c2r6oodom.cloudfront.net/p/h/170.jpg"
                />
                <div css={{ padding: 16 }}>
                  <h4
                    css={{
                      marginTop: 0,
                      marginBottom: 8,
                      fontSize: div.fontSize5 / 100 + 'rem'
                    }}
                  >
                    Card Title
                  </h4>
                  <p
                    css={{
                      lineHeight: div.lineHeightCopy / 100,
                      fontSize: div.fontSize6 / 100 + 'rem',
                      marginBottom: 16,
                      marginTop: 0
                    }}
                  >
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua.
                  </p>
                  <a
                    href="#0"
                    css={{
                      background: tealScale[4],
                      color: 'white',
                      borderRadius: 9999,
                      display: 'inline-block',
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 4,
                      paddingBottom: 4,
                      fontSize: 12,
                      textDecoration: 'none'
                    }}
                  >
                    Read More
                  </a>
                </div>
              </article>
            </div>
          </section>
          <section css={{ display: 'flex', marginTop: 32 }}>
            <div css={{ width: 64, marginRight: 32 }}>
              <img
                css={{
                  display: 'block',
                  borderRadius: div.borderRadius2 / 100 + 'rem',
                  boxShadow: div.boxShadow1
                }}
                src="http://dc28c2r6oodom.cloudfront.net/avatars/2.jpg"
              />
            </div>
            <div css={{ width: 64, marginRight: 32 }}>
              <img
                css={{
                  display: 'block',
                  borderRadius: div.borderRadius3 / 100 + 'rem',
                  boxShadow: div.boxShadow2
                }}
                src="http://dc28c2r6oodom.cloudfront.net/avatars/4.jpg"
              />
            </div>
            <div css={{ width: 64, marginRight: 32 }}>
              <img
                css={{
                  display: 'block',
                  borderRadius: div.borderRadius4 / 100 + 'rem',
                  boxShadow: div.boxShadow3
                }}
                src="http://dc28c2r6oodom.cloudfront.net/avatars/5.jpg"
              />
            </div>
            <div css={{ width: 64, marginRight: 32 }}>
              <img
                css={{
                  display: 'block',
                  borderRadius: 9999,
                  boxShadow: div.boxShadow4
                }}
                src="http://dc28c2r6oodom.cloudfront.net/avatars/6.jpg"
              />
            </div>
          </section>

          <section css={{ marginTop: 64 }}>
            <h5>Colors</h5>
            <div css={{ display: 'flex', fontSize: 12 }}>
              <table
                css={{
                  borderCollapse: 'collapse',
                  cellSpacing: '0',
                  cellPadding: 0,
                  flexGrow: 1
                }}
              >
                <tbody>
                  {grayScale.map((color, i) => (
                    <tr>
                      <td>
                        <div
                          css={{ background: color, padding: 12, width: '10%' }}
                        ></div>
                      </td>
                      <td>{color}</td>
                      <td
                        css={{
                          fontWeight:
                            chroma.contrast(color, 'white') > 4.49 ? 800 : 400
                        }}
                      >
                        {chroma.contrast(color, 'white').toFixed(2)}
                      </td>
                      <td
                        css={{
                          fontWeight:
                            chroma.contrast(color, 'black') > 4.49 ? 800 : 400
                        }}
                      >
                        {chroma.contrast(color, 'black').toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table
                css={{
                  borderCollapse: 'collapse',
                  cellSpacing: '0',
                  cellPadding: 0,
                  flexGrow: 1
                }}
              >
                <tbody>
                  {blueScale.map((color, i) => (
                    <tr>
                      <td>
                        <div
                          css={{ background: color, padding: 12, width: '10%' }}
                        ></div>
                      </td>
                      <td>{color}</td>
                      <td
                        css={{
                          fontWeight:
                            chroma.contrast(color, 'white') > 4.49 ? 800 : 400
                        }}
                      >
                        {chroma.contrast(color, 'white').toFixed(2)}
                      </td>
                      <td
                        css={{
                          fontWeight:
                            chroma.contrast(color, 'black') > 4.49 ? 800 : 400
                        }}
                      >
                        {chroma.contrast(color, 'black').toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table
                css={{
                  borderCollapse: 'collapse',
                  cellSpacing: '0',
                  cellPadding: 0,
                  flexGrow: 1
                }}
              >
                <tbody>
                  {indigoScale.map((color, i) => (
                    <tr>
                      <td>
                        <div
                          css={{ background: color, padding: 12, width: '10%' }}
                        ></div>
                      </td>
                      <td>{color}</td>
                      <td
                        css={{
                          fontWeight:
                            chroma.contrast(color, 'white') > 4.49 ? 800 : 400
                        }}
                      >
                        {chroma.contrast(color, 'white').toFixed(2)}
                      </td>
                      <td
                        css={{
                          fontWeight:
                            chroma.contrast(color, 'black') > 4.49 ? 800 : 400
                        }}
                      >
                        {chroma.contrast(color, 'black').toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table
                css={{
                  borderCollapse: 'collapse',
                  cellSpacing: '0',
                  cellPadding: 0,
                  flexGrow: 1
                }}
              >
                <tbody>
                  {violetScale.map((color, i) => (
                    <tr>
                      <td>
                        <div
                          css={{ background: color, padding: 12, width: '10%' }}
                        ></div>
                      </td>
                      <td>{color}</td>
                      <td
                        css={{
                          fontWeight:
                            chroma.contrast(color, 'white') > 4.49 ? 800 : 400
                        }}
                      >
                        {chroma.contrast(color, 'white').toFixed(2)}
                      </td>
                      <td
                        css={{
                          fontWeight:
                            chroma.contrast(color, 'black') > 4.49 ? 800 : 400
                        }}
                      >
                        {chroma.contrast(color, 'black').toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table
                css={{
                  borderCollapse: 'collapse',
                  cellSpacing: '0',
                  cellPadding: 0,
                  flexGrow: 1
                }}
              >
                <tbody>
                  {redScale.map((color, i) => (
                    <tr>
                      <td>
                        <div
                          css={{ background: color, padding: 12, width: '10%' }}
                        ></div>
                      </td>
                      <td>{color}</td>
                      <td
                        css={{
                          fontWeight:
                            chroma.contrast(color, 'white') > 4.49 ? 800 : 400
                        }}
                      >
                        {chroma.contrast(color, 'white').toFixed(2)}
                      </td>
                      <td
                        css={{
                          fontWeight:
                            chroma.contrast(color, 'black') > 4.49 ? 800 : 400
                        }}
                      >
                        {chroma.contrast(color, 'black').toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table
                css={{
                  borderCollapse: 'collapse',
                  cellSpacing: '0',
                  cellPadding: 0,
                  flexGrow: 1
                }}
              >
                <tbody>
                  {goldScale.map((color, i) => (
                    <tr>
                      <td>
                        <div
                          css={{ background: color, padding: 12, width: '10%' }}
                        ></div>
                      </td>
                      <td>{color}</td>
                      <td
                        css={{
                          fontWeight:
                            chroma.contrast(color, 'white') > 4.49 ? 800 : 400
                        }}
                      >
                        {chroma.contrast(color, 'white').toFixed(2)}
                      </td>
                      <td
                        css={{
                          fontWeight:
                            chroma.contrast(color, 'black') > 4.49 ? 800 : 400
                        }}
                      >
                        {chroma.contrast(color, 'black').toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table
                css={{
                  borderCollapse: 'collapse',
                  cellSpacing: '0',
                  cellPadding: 0,
                  flexGrow: 1
                }}
              >
                <tbody>
                  {tealScale.map((color, i) => (
                    <tr>
                      <td>
                        <div
                          css={{ background: color, padding: 12, width: '10%' }}
                        ></div>
                      </td>
                      <td>{color}</td>
                      <td
                        css={{
                          fontWeight:
                            chroma.contrast(color, 'white') > 4.49 ? 800 : 400
                        }}
                      >
                        {chroma.contrast(color, 'white').toFixed(2)}
                      </td>
                      <td
                        css={{
                          fontWeight:
                            chroma.contrast(color, 'black') > 4.49 ? 800 : 400
                        }}
                      >
                        {chroma.contrast(color, 'black').toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table
                css={{
                  borderCollapse: 'collapse',
                  cellSpacing: '0',
                  cellPadding: 0,
                  flexGrow: 1
                }}
              >
                <tbody>
                  {greenScale.map((color, i) => (
                    <tr>
                      <td>
                        <div
                          css={{ background: color, padding: 12, width: '10%' }}
                        ></div>
                      </td>
                      <td>{color}</td>
                      <td
                        css={{
                          fontWeight:
                            chroma.contrast(color, 'white') > 4.49 ? 800 : 400
                        }}
                      >
                        {chroma.contrast(color, 'white').toFixed(2)}
                      </td>
                      <td
                        css={{
                          fontWeight:
                            chroma.contrast(color, 'black') > 4.49 ? 800 : 400
                        }}
                      >
                        {chroma.contrast(color, 'black').toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
          <section className="pt4">
            <h5>Type scale</h5>
            <div className="sans-serif mb2 f-headline fw7">Headline</div>
            <div className="sans-serif mb2 f-subheadline fw7">Subheadline</div>
            <div className="sans-serif mb2 f1 fw7">Font Size 1</div>
            <div className="sans-serif mb2 f2 fw7">Font Size 2</div>
            <div className="sans-serif mb2 f3 fw7">Font Size 3</div>
            <div className="sans-serif mb2 f4">Font Size 4</div>
            <div className="sans-serif mb2 f5">Font Size 5</div>
            <div className="sans-serif mb2 f6">Font Size 6</div>
            <div className="sans-serif f7">Font Size 7</div>
          </section>
          <section className="pt4 sans-serif">
            <h5>Font Family</h5>
            <article css={{ display: 'flex', fontSize: 24 }}>
              <div className="mb2 sans-serif pr5">
                <h6
                  css={{
                    marginBottom: 16,
                    fontSize: 16,
                    fontFamily: div.sansSerif
                  }}
                >
                  {div.sansSerif}
                </h6>
                <span className="fw7">ABCDEFGHIJKLMNOPQRSTUVWXYZ</span>
                <br />
                abcdefghijklmnopqrstuvwxyz
                <br />
                1234567890!@#$%^&*()_+=
              </div>
              <div className="mb2 serif pr5">
                <h6
                  css={{
                    fontFamily: div.sansSerif,
                    marginBottom: 16,
                    fontSize: 16
                  }}
                >
                  {div.serif}
                </h6>
                <span className="fw7">ABCDEFGHIJKLMNOPQRSTUVWXYZ</span>
                <br />
                abcdefghijklmnopqrstuvwxyz
                <br />
                1234567890!@#$%^&*()_+=
              </div>
              <div className="mb2 code">
                <h6
                  css={{
                    fontFamily: div.sansSerif,
                    marginBottom: 16,
                    fontSize: 16
                  }}
                >
                  {div.monospace}
                </h6>
                <span className="fw7">ABCDEFGHIJKLMNOPQRSTUVWXYZ</span>
                <br />
                abcdefghijklmnopqrstuvwxyz
                <br />
                1234567890!@#$%^&*()_+=
              </div>
            </article>
          </section>
          <section className="pv5 sans-serif">
            <h5>Line heights</h5>
            <article css={{ display: 'flex', fontSize: 32 }}>
              <div>
                <h6 css={{ fontSize: 16, marginBottom: 8 }}>Solid</h6>
                <div className="mb2 fw7 lh-solid measure">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr
                </div>
              </div>
              <div>
                <h6 css={{ fontSize: 16, marginBottom: 8 }}>Title</h6>
                <div className="mb2 fw7 lh-title measure">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr
                </div>
              </div>
              <div>
                <h6 css={{ fontSize: 16, marginBottom: 8 }}>Copy</h6>
                <div className="mb2 lh-copy measure" style={{ fontSize: 16 }}>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.
                </div>
              </div>
            </article>
          </section>
          <div css={{ display: 'flex' }}>
            <section css={{ paddingBottom: 32, width: '50%' }}>
              <h5>Spacing</h5>
              <div
                css={{
                  display: 'flex'
                }}
              >
                <article
                  css={{
                    position: 'relative',
                    height: (div.spacing7 / 100) * 2 + 'rem',
                    width: '50%',
                    paddingRight: 16
                  }}
                >
                  <div className="absolute ba pa1"></div>
                  <div className="absolute ba pa2"></div>
                  <div className="absolute ba pa3"></div>
                  <div className="absolute ba pa4"></div>
                  <div className="absolute ba pa5"></div>
                  <div className="absolute ba pa6"></div>
                  <div className="absolute ba pa7"></div>
                </article>
              </div>
            </section>
            <div css={{ width: '50%', paddingLeft: 16 }}>
              <section css={{ paddingBottom: 64, width: '100%' }}>
                <h6>Shadows</h6>
                <div css={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="shadow-1 pa4"></div>
                  <div className="shadow-2 pa4"></div>
                  <div className="shadow-3 pa4"></div>
                  <div className="shadow-4 pa4"></div>
                  <div className="shadow-5 pa4"></div>
                </div>
              </section>
              <section css={{ paddingBottom: 64, width: '100%' }}>
                <h6>Border Radius</h6>
                <div css={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="ba mr2 br1 pv4 ph5"></div>
                  <div className="ba mr2 br2 pv4 ph5"></div>
                  <div className="ba mr2 br3 pv4 ph5"></div>
                  <div className="ba br4 pv4 ph5"></div>
                </div>
                <div css={{ display: 'flex', marginTop: 16 }}>
                  <div className="ba mr2 br-100 pv4 ph5"></div>
                  <div className="ba br-pill ph5 pv2"></div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const GridItem = ({ div, ...props }) => (
  <div
    css={{
      width: '25%',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '16px',
      height: '23vh'
    }}
    {...props}
  >
    <a
      css={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        overflow: 'hidden'
      }}
    >
      <div
        css={{
          filter: `blur(${div.blur}px)`,
          opacity: div.opacity / 100,
          mixBlendMode: div.mixBlendMode,
          backgroundImage: 'url(' + div.backgroundImage + ')',
          backgroundPositionX: div.backgroundPositionX,
          backgroundPositionY: div.backgroundPositionY,
          display: div.display,
          fontWeight: div.fontWeight,
          fontSize: div.fontSize + 'px',
          fontStyle: div.fontStyle,
          alignItems: div.alignItems,
          textAlign: div.textAlign,
          textTransform: div.textTransform,
          backgroundColor: div.bg,
          backgroundSize: div.backgroundSize,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }}
      >
        Aa
      </div>
    </a>
  </div>
)

const GridView = ({ pins, onSelect }) => (
  <Flex
    css={{
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingTop: '32px',
      paddingBottom: '32px',
      paddingLeft: '8px',
      paddingRight: '8px'
    }}
  >
    {Array(16)
      .fill(0)
      .map((_, i) => {
        const baseDiv = randomDiv()
        const divWithPins = { ...baseDiv, ...pins }
        const div = {
          ...divWithPins
        }

        return <GridItem key={i} onClick={() => onSelect(div)} div={div} />
      })}
  </Flex>
)

const Sidebar = ({ onChange, value, pins, onPin }) => {
  const changeValue = key => e => {
    onChange(key, e.target.value)
  }

  const blueScale = chroma
    .scale(['#000000', value.blue, '#ffffff'])
    .mode(value.mode)
    .correctLightness()
    .colors(12)
    .slice(1, -1)
  const indigoScale = chroma
    .scale(['#000000', value.indigo, '#ffffff'])
    .mode(value.mode)
    .correctLightness()
    .colors(12)
    .slice(1, -1)
  const violetScale = chroma
    .scale(['#000000', value.violet, '#ffffff'])
    .mode(value.mode)
    .correctLightness()
    .colors(12)
    .slice(1, -1)
  const redScale = chroma
    .scale(['#000000', value.red, '#ffffff'])
    .mode(value.mode)
    .correctLightness()
    .colors(12)
    .slice(1, -1)
  const goldScale = chroma
    .scale(['#000000', value.gold, '#ffffff'])
    .mode(value.mode)
    .correctLightness()
    .colors(12)
    .slice(1, -1)
  const greenScale = chroma
    .scale(['#000000', value.green, '#ffffff'])
    .mode(value.mode)
    .correctLightness()
    .colors(12)
    .slice(1, -1)
  const tealScale = chroma
    .scale(['#000000', value.teal, '#ffffff'])
    .mode(value.mode)
    .correctLightness()
    .colors(12)
    .slice(1, -1)
  const grayScale = chroma
    .scale(['#000000', value.gray, '#ffffff'])
    .mode(value.mode)
    .correctLightness()
    .colors(12)
    .slice(1, -1)

  return (
    <>
      <SidebarHeading>Tachyons Custom Theme</SidebarHeading>
      <h5 css={{ marginBottom: 16 }}>Colors</h5>
      <Color
        label="Canvas"
        value={value.canvasBg}
        onChange={changeValue('canvasBg')}
        active={pins.canvasBg}
        onLock={() => onPin('canvasBg', value.canvasBg)}
        width={128}
      />
      <Color
        label="Gray"
        value={value.gray}
        onChange={changeValue('gray')}
        active={pins.gray}
        onLock={() => onPin('gray', value.gray)}
        width={128}
      />
      <Color
        label="Blue"
        value={value.blue}
        onChange={changeValue('blue')}
        active={pins.blue}
        onLock={() => onPin('blue', value.blue)}
        width={128}
      />
      <Color
        label="Indigo"
        value={value.indigo}
        onChange={changeValue('indigo')}
        active={pins.indigo}
        onLock={() => onPin('indigo', value.indigo)}
        width={128}
      />
      <Color
        label="Violet"
        value={value.violet}
        onChange={changeValue('violet')}
        active={pins.violet}
        onLock={() => onPin('violet', value.violet)}
        width={128}
      />
      <Color
        label="Red"
        value={value.red}
        onChange={changeValue('red')}
        active={pins.red}
        onLock={() => onPin('red', value.red)}
        width={128}
      />
      <Color
        label="gold"
        value={value.gold}
        onChange={changeValue('gold')}
        active={pins.gold}
        onLock={() => onPin('gold', value.gold)}
        width={128}
      />
      <Color
        label="green"
        value={value.green}
        onChange={changeValue('green')}
        active={pins.green}
        onLock={() => onPin('green', value.green)}
        width={128}
      />
      <Color
        label="teal"
        value={value.teal}
        onChange={changeValue('teal')}
        active={pins.teal}
        onLock={() => onPin('teal', value.teal)}
        width={128}
      />
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.mode}
          onClick={() => onPin('mode', value.mode)}
        />
        <Label>Scale Mode</Label>
        <select value={value.mode} onChange={changeValue('mode')}>
          <option>lab</option>
          <option>hsl</option>
          <option>lch</option>
          <option>lrgb</option>
          <option>rgb</option>
        </select>
      </Flex>
      <SectionBorder my={3} />
      <h5 css={{ marginTop: 0, marginBottom: 16 }}>Font Family</h5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.sansSerif}
          onClick={() => onPin('sansSerif', value.sansSerif)}
        />
        <Label>Sans Serif</Label>
        <Input
          width={1}
          fontSize={0}
          value={value.sansSerif}
          type="text"
          onChange={changeValue('sansSerif')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.serif}
          onClick={() => onPin('serif', value.serif)}
        />
        <Label>Serif</Label>
        <Input
          width={1}
          value={value.serif}
          type="text"
          onChange={changeValue('serif')}
          fontSize={0}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.monospace}
          onClick={() => onPin('monospace', value.monospace)}
        />
        <Label>Monospace</Label>
        <Input
          width={1}
          value={value.monospace}
          type="text"
          fontSize={0}
          onChange={changeValue('monospace')}
        />
      </Flex>
      <SectionBorder my={3} />
      <h5 css={{ marginTop: 0, marginBottom: 16 }}>Type Scale</h5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSizeHeadline}
          onClick={() => onPin('fontSizeHeadline', value.fontSizeHeadline)}
        />
        <Label>Headline</Label>
        <Input
          min={0}
          max={1600}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.fontSizeHeadline)}
          type="range"
          onChange={changeValue('fontSizeHeadline')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.fontSizeHeadline / 100 + 'rem'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSizeSubheadline}
          onClick={() =>
            onPin('fontSizeSubheadline', value.fontSizeSubheadline)
          }
        />
        <Label>Subheadline</Label>
        <Input
          min={50}
          max={1600}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.fontSizeSubheadline)}
          type="range"
          onChange={changeValue('fontSizeSubheadline')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.fontSizeSubheadline / 100 + 'rem'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize1}
          onClick={() => onPin('fontSize1', value.fontSize1)}
        />
        <Label>Font Size 1</Label>
        <Input
          min={0}
          max={1600}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.fontSize1)}
          type="range"
          onChange={changeValue('fontSize1')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.fontSize1 / 100 + 'rem'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize2}
          onClick={() => onPin('fontSize2', value.fontSize2)}
        />
        <Label>Font Size 2</Label>
        <Input
          min={0}
          max={1600}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.fontSize2)}
          type="range"
          onChange={changeValue('fontSize2')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.fontSize2 / 100 + 'rem'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize3}
          onClick={() => onPin('fontSize3', value.fontSize3)}
        />
        <Label>Font Size 3</Label>
        <Input
          min={0}
          max={1600}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.fontSize3)}
          type="range"
          onChange={changeValue('fontSize3')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.fontSize3 / 100 + 'rem'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize4}
          onClick={() => onPin('fontSize4', value.fontSize4)}
        />
        <Label>Font Size 4</Label>
        <Input
          min={0}
          max={1600}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.fontSize4)}
          type="range"
          onChange={changeValue('fontSize4')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.fontSize4 / 100 + 'rem'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize5}
          onClick={() => onPin('fontSize5', value.fontSize5)}
        />
        <Label>Font Size 5</Label>
        <Input
          min={0}
          max={1600}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.fontSize5)}
          type="range"
          onChange={changeValue('fontSize5')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.fontSize5 / 100 + 'rem'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize6}
          onClick={() => onPin('fontSize6', value.fontSize6)}
        />
        <Label>Font Size 6</Label>
        <Input
          min={0}
          max={1600}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.fontSize6)}
          type="range"
          onChange={changeValue('fontSize6')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.fontSize6 / 100 + 'rem'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize7}
          onClick={() => onPin('fontSize7', value.fontSize7)}
        />
        <Label>Font Size 7</Label>
        <Input
          min={0}
          max={1600}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.fontSize7)}
          type="range"
          onChange={changeValue('fontSize7')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.fontSize7 / 100 + 'rem'}
        />
      </Flex>
      <SectionBorder my={3} />
      <h5>Line Height</h5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.lineHeightSolid}
          onClick={() => onPin('lineHeightSolid', value.lineHeightSolid)}
        />
        <Label>Solid</Label>
        <Input
          min={0}
          max={200}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.lineHeightSolid)}
          type="range"
          onChange={changeValue('lineHeightSolid')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.lineHeightSolid / 100}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.lineHeightTitle}
          onClick={() => onPin('lineHeightTitle', value.lineHeightTitle)}
        />
        <Label>Title</Label>
        <Input
          min={0}
          max={200}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.lineHeightTitle)}
          type="range"
          onChange={changeValue('lineHeightTitle')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.lineHeightTitle / 100}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.lineHeightCopy}
          onClick={() => onPin('lineHeightCopy', value.lineHeightCopy)}
        />
        <Label>Copy</Label>
        <Input
          min={0}
          max={200}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.lineHeightCopy)}
          type="range"
          onChange={changeValue('lineHeightCopy')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.lineHeightCopy / 100}
        />
      </Flex>
      <SectionBorder my={3} />
      <h5 css={{ marginBottom: 16 }}>Spacing</h5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spacing1}
          onClick={() => onPin('spacing1', value.spacing1)}
        />
        <Label>1</Label>
        <Input
          min={0}
          max={1600}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.spacing1)}
          type="range"
          onChange={changeValue('spacing1')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.spacing1 / 100 + 'rem'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spacing2}
          onClick={() => onPin('spacing2', value.spacing2)}
        />
        <Label>2</Label>
        <Input
          min={0}
          max={1600}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.spacing2)}
          type="range"
          onChange={changeValue('spacing2')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.spacing2 / 100 + 'rem'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spacing3}
          onClick={() => onPin('spacing3', value.spacing3)}
        />
        <Label>3</Label>
        <Input
          min={0}
          max={1600}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.spacing3)}
          type="range"
          onChange={changeValue('spacing3')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.spacing3 / 100 + 'rem'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spacing4}
          onClick={() => onPin('spacing4', value.spacing4)}
        />
        <Label>4</Label>
        <Input
          min={0}
          max={1600}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.spacing4)}
          type="range"
          onChange={changeValue('spacing4')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.spacing4 / 100 + 'rem'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spacing5}
          onClick={() => onPin('spacing5', value.spacing5)}
        />
        <Label>5</Label>
        <Input
          min={0}
          max={1600}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.spacing5)}
          type="range"
          onChange={changeValue('spacing5')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.spacing5 / 100 + 'rem'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spacing6}
          onClick={() => onPin('spacing6', value.spacing6)}
        />
        <Label>6</Label>
        <Input
          min={0}
          max={1600}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.spacing6)}
          type="range"
          onChange={changeValue('spacing6')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.spacing6 / 100 + 'rem'}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.spacing7}
          onClick={() => onPin('spacing7', value.spacing7)}
        />
        <Label>7</Label>
        <Input
          min={0}
          max={1600}
          steps={5}
          width={1}
          mr={2}
          value={Number(value.spacing7)}
          type="range"
          onChange={changeValue('spacing7')}
        />
        <Span
          width="96px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.spacing7 / 100 + 'rem'}
        />
      </Flex>
      <SectionBorder my={3} />
      <h5 css={{ marginBottom: 16 }}>Border Radius</h5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderRadius1}
          onClick={() => onPin('borderRadius1', value.borderRadius1)}
        />
        <Label>1</Label>
        <Input
          min={0}
          max={400}
          steps={1}
          width="100%"
          mr={2}
          value={Number(value.borderRadius1)}
          type="range"
          onChange={changeValue('borderRadius1')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.borderRadius1)}
          type="number"
          onChange={changeValue('borderRadius1')}
        />
        <Span
          width={32}
          textAlign="right"
          ml="auto"
          fontSize={0}
          children="rem"
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderRadius2}
          onClick={() => onPin('borderRadius2', value.borderRadius2)}
        />
        <Label>2</Label>
        <Input
          min={0}
          max={400}
          steps={1}
          width="100%"
          mr={2}
          value={Number(value.borderRadius2)}
          type="range"
          onChange={changeValue('borderRadius2')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.borderRadius2)}
          type="number"
          onChange={changeValue('borderRadius2')}
        />
        <Span
          width={32}
          textAlign="right"
          ml="auto"
          fontSize={0}
          children="rem"
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderRadius3}
          onClick={() => onPin('borderRadius3', value.borderRadius3)}
        />
        <Label>3</Label>
        <Input
          min={0}
          max={400}
          steps={1}
          width="100%"
          mr={2}
          value={Number(value.borderRadius3)}
          type="range"
          onChange={changeValue('borderRadius3')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.borderRadius3)}
          type="number"
          onChange={changeValue('borderRadius3')}
        />
        <Span
          width={32}
          textAlign="right"
          ml="auto"
          fontSize={0}
          children="rem"
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderRadius4}
          onClick={() => onPin('borderRadius4', value.borderRadius4)}
        />
        <Label>4</Label>
        <Input
          min={0}
          max={400}
          steps={5}
          width="100%"
          mr={2}
          value={Number(value.borderRadius4)}
          type="range"
          onChange={changeValue('borderRadius4')}
        />
        <Input
          width={48}
          mr={2}
          value={Number(value.borderRadius4)}
          type="number"
          onChange={changeValue('borderRadius4')}
        />
        <Span
          width={32}
          textAlign="right"
          ml="auto"
          fontSize={0}
          children="rem"
        />
      </Flex>
      <SectionBorder my={3} />
      <h5 css={{ marginTop: 0, marginBottom: 16 }}>Box Shadow</h5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.boxShadow1}
          onClick={() => onPin('boxShadow1', value.boxShadow1)}
        />
        <Label>1</Label>
        <Input
          width={1}
          fontSize={0}
          value={value.boxShadow1}
          type="text"
          onChange={changeValue('boxShadow1')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.boxShadow2}
          onClick={() => onPin('boxShadow2', value.boxShadow2)}
        />
        <Label>2</Label>
        <Input
          width={1}
          fontSize={0}
          value={value.boxShadow2}
          type="text"
          onChange={changeValue('boxShadow2')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.boxShadow3}
          onClick={() => onPin('boxShadow3', value.boxShadow3)}
        />
        <Label>3</Label>
        <Input
          width={1}
          fontSize={0}
          value={value.boxShadow3}
          type="text"
          onChange={changeValue('boxShadow3')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.boxShadow4}
          onClick={() => onPin('boxShadow4', value.boxShadow4)}
        />
        <Label>4</Label>
        <Input
          width={1}
          fontSize={0}
          value={value.boxShadow4}
          type="text"
          onChange={changeValue('boxShadow4')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.boxShadow5}
          onClick={() => onPin('boxShadow5', value.boxShadow5)}
        />
        <Label>5</Label>
        <Input
          width={1}
          fontSize={0}
          value={value.boxShadow5}
          type="text"
          onChange={changeValue('boxShadow5')}
        />
      </Flex>
      <SectionBorder my={3} />
      <H5 mb={1}>
        <Span fontSize={0}>Css Variables</Span>
      </H5>
      <Textarea
        bg="transparent"
        height={128}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        fontSize={0}
        readOnly
        value={
          `root: {\n` +
          `--sans-serif: ${value.sansSerif};\n` +
          `--serif: ${value.serif};\n` +
          `--monospace: ${value.monospace};\n\n` +
          `--font-size-headline: ${value.fontSizeHeadline / 100}rem;\n` +
          `--font-size-subheadline: ${value.fontSizeSubheadline / 100}rem;\n` +
          `--font-size-1: ${value.fontSize1 / 100}rem;\n` +
          `--font-size-2: ${value.fontSize2 / 100}rem;\n` +
          `--font-size-3: ${value.fontSize3 / 100}rem;\n` +
          `--font-size-4: ${value.fontSize4 / 100}rem;\n` +
          `--font-size-5: ${value.fontSize5 / 100}rem;\n` +
          `--font-size-6: ${value.fontSize6 / 100}rem;\n` +
          `--font-size-7: ${value.fontSize7 / 100}rem;\n\n` +
          `--measure: 30em;\n` +
          `--measure-narrow: 20em;\n` +
          `--measure-wide: 34em;\n\n` +
          `--letter-spacing-tight:-.05em;\n` +
          `--letter-spacing-1:.1em;\n` +
          `--letter-spacing-2:.25em;\n\n` +
          `--line-height-solid: ${value.lineHeightSolid / 100};\n` +
          `--line-height-title: ${value.lineHeightTitle / 100};\n` +
          `--line-height-copy: ${value.lineHeightCopy / 100};\n\n` +
          `--spacing-extra-small: ${value.spacing1 / 100}rem;\n` +
          `--spacing-small: ${value.spacing2 / 100}rem;\n` +
          `--spacing-medium: ${value.spacing3 / 100}rem;\n` +
          `--spacing-large: ${value.spacing4 / 100}rem;\n` +
          `--spacing-extra-large: ${value.spacing5 / 100}rem;\n` +
          `--spacing-extra-extra-large: ${value.spacing6 / 100}rem;\n` +
          `--spacing-extra-extra-extra-large: ${value.spacing7 / 100}rem;\n\n` +
          `--border-radius-1: ${value.borderRadius1 / 100}rem;\n` +
          `--border-radius-2: ${value.borderRadius2 / 100}rem;\n` +
          `--border-radius-3: ${value.borderRadius3 / 100}rem;\n` +
          `--border-radius-4: ${value.borderRadius4 / 100}rem;\n` +
          `--border-radius-circle: 100%;\n` +
          `--border-radius-pill: 9999px;\n\n` +
          `--box-shadow-1: ${value.boxShadow1};\n` +
          `--box-shadow-2: ${value.boxShadow2};\n` +
          `--box-shadow-3: ${value.boxShadow3};\n` +
          `--box-shadow-4: ${value.boxShadow4};\n` +
          `--box-shadow-5: ${value.boxShadow5};\n\n` +
          `--height-1: 1rem;\n` +
          `--height-2: 2rem;\n` +
          `--height-3: 4rem;\n` +
          `--height-4: 8rem;\n` +
          `--height-5: 16rem;\n` +
          `--width-1: 1rem;\n` +
          `--width-2: 2rem;\n` +
          `--width-3: 4rem;\n` +
          `--width-4: 8rem;\n` +
          `--width-5: 16rem;\n\n` +
          `--max-width-1: 1rem;\n` +
          `--max-width-2: 2rem;\n` +
          `--max-width-3: 4rem;\n` +
          `--max-width-4: 8rem;\n` +
          `--max-width-5: 16rem;\n` +
          `--max-width-6: 32rem;\n` +
          `--max-width-7: 48rem;\n` +
          `--max-width-8: 64rem;\n` +
          `--max-width-9: 96rem;\n\n` +
          `--red-000: ${redScale[0]};\n` +
          `--red-100: ${redScale[1]};\n` +
          `--red-200: ${redScale[2]};\n` +
          `--red-300: ${redScale[3]};\n` +
          `--red-400: ${redScale[4]};\n` +
          `--red-500: ${redScale[5]};\n` +
          `--red-600: ${redScale[6]};\n` +
          `--red-700: ${redScale[7]};\n` +
          `--red-800: ${redScale[8]};\n` +
          `--red-900: ${redScale[9]};\n` +
          `--gold-000: ${goldScale[0]};\n` +
          `--gold-100: ${goldScale[1]};\n` +
          `--gold-200: ${goldScale[2]};\n` +
          `--gold-300: ${goldScale[3]};\n` +
          `--gold-400: ${goldScale[4]};\n` +
          `--gold-500: ${goldScale[5]};\n` +
          `--gold-600: ${goldScale[6]};\n` +
          `--gold-700: ${goldScale[7]};\n` +
          `--gold-800: ${goldScale[8]};\n` +
          `--gold-900: ${goldScale[9]};\n` +
          `--green-000: ${greenScale[0]};\n` +
          `--green-100: ${greenScale[1]};\n` +
          `--green-200: ${greenScale[2]};\n` +
          `--green-300: ${greenScale[3]};\n` +
          `--green-400: ${greenScale[4]};\n` +
          `--green-500: ${greenScale[5]};\n` +
          `--green-600: ${greenScale[6]};\n` +
          `--green-700: ${greenScale[7]};\n` +
          `--green-800: ${greenScale[8]};\n` +
          `--green-900: ${greenScale[9]};\n` +
          `--teal-000: ${tealScale[0]};\n` +
          `--teal-100: ${tealScale[1]};\n` +
          `--teal-200: ${tealScale[2]};\n` +
          `--teal-300: ${tealScale[3]};\n` +
          `--teal-400: ${tealScale[4]};\n` +
          `--teal-500: ${tealScale[5]};\n` +
          `--teal-600: ${tealScale[6]};\n` +
          `--teal-700: ${tealScale[7]};\n` +
          `--teal-800: ${tealScale[8]};\n` +
          `--teal-900: ${tealScale[9]};\n` +
          `--blue-000: ${blueScale[0]};\n` +
          `--blue-100: ${blueScale[1]};\n` +
          `--blue-200: ${blueScale[2]};\n` +
          `--blue-300: ${blueScale[3]};\n` +
          `--blue-400: ${blueScale[4]};\n` +
          `--blue-500: ${blueScale[5]};\n` +
          `--blue-600: ${blueScale[6]};\n` +
          `--blue-700: ${blueScale[7]};\n` +
          `--blue-800: ${blueScale[8]};\n` +
          `--blue-900: ${blueScale[9]};\n` +
          `--indigo-000: ${indigoScale[0]};\n` +
          `--indigo-100: ${indigoScale[1]};\n` +
          `--indigo-200: ${indigoScale[2]};\n` +
          `--indigo-300: ${indigoScale[3]};\n` +
          `--indigo-400: ${indigoScale[4]};\n` +
          `--indigo-500: ${indigoScale[5]};\n` +
          `--indigo-600: ${indigoScale[6]};\n` +
          `--indigo-700: ${indigoScale[7]};\n` +
          `--indigo-800: ${indigoScale[8]};\n` +
          `--indigo-900: ${indigoScale[9]};\n` +
          `--violet-000: ${violetScale[0]};\n` +
          `--violet-100: ${violetScale[1]};\n` +
          `--violet-200: ${violetScale[2]};\n` +
          `--violet-300: ${violetScale[3]};\n` +
          `--violet-400: ${violetScale[4]};\n` +
          `--violet-500: ${violetScale[5]};\n` +
          `--violet-600: ${violetScale[6]};\n` +
          `--violet-700: ${violetScale[7]};\n` +
          `--violet-800: ${violetScale[8]};\n` +
          `--violet-900: ${violetScale[9]};\n` +
          `--gray-000: ${grayScale[0]};\n` +
          `--gray-100: ${grayScale[1]};\n` +
          `--gray-200: ${grayScale[2]};\n` +
          `--gray-300: ${grayScale[3]};\n` +
          `--gray-400: ${grayScale[4]};\n` +
          `--gray-500: ${grayScale[5]};\n` +
          `--gray-600: ${grayScale[6]};\n` +
          `--gray-700: ${grayScale[7]};\n` +
          `--gray-800: ${grayScale[8]};\n` +
          `--gray-900: ${grayScale[9]};\n` +
          '}'
        }
      />
      <P fontSize={0} mt={3}>
        For use with{' '}
        <a href="https://github.com/tachyons-css/tachyons-custom">
          Tachyons Custom
        </a>
        . Copy and paste the above vars into src/_variables.css.
      </P>
      <H5 mb={1}>
        <Span fontSize={0}>Generator Config</Span>
      </H5>
      <Textarea
        bg="transparent"
        height={128}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        fontSize={0}
        readOnly
        value={
          `{\n` +
          `"typeScale": [ ${value.fontSizeHeadline /
            100}, ${value.fontSizeSubheadline / 100}, ${value.fontSize1 /
            100}, ${value.fontSize2 / 100}, ${value.fontSize3 /
            100}, ${value.fontSize4 / 100}, ${value.fontSize5 /
            100}, ${value.fontSize6 / 100}, ${value.fontSize7 / 100} ],\n` +
          `"spacing": [ ${value.spacing1 / 100}, ${value.spacing2 /
            100}, ${value.spacing3 / 100}, ${value.spacing4 /
            100}, ${value.spacing5 / 100}, ${value.spacing6 /
            100}, ${value.spacing7 / 100} ],\n` +
          `"lineHeight": [ ${value.lineHeightSolid /
            100}, ${value.lineHeightTitle / 100}, ${value.lineHeightCopy /
            100} ],\n` +
          `"colors": {\n` +
          `"red-0": "${redScale[0]}",\n` +
          `"red-1": "${redScale[1]}",\n` +
          `"red-2": "${redScale[2]}",\n` +
          `"red-3": "${redScale[3]}",\n` +
          `"red-4": "${redScale[4]}",\n` +
          `"red-5": "${redScale[5]}",\n` +
          `"red-6": "${redScale[6]}",\n` +
          `"red-7": "${redScale[7]}",\n` +
          `"red-8": "${redScale[8]}",\n` +
          `"red-9": "${redScale[9]}",\n` +
          `"gold-0": "${goldScale[0]}",\n` +
          `"gold-1": "${goldScale[1]}",\n` +
          `"gold-2": "${goldScale[2]}",\n` +
          `"gold-3": "${goldScale[3]}",\n` +
          `"gold-4": "${goldScale[4]}",\n` +
          `"gold-5": "${goldScale[5]}",\n` +
          `"gold-6": "${goldScale[6]}",\n` +
          `"gold-7": "${goldScale[7]}",\n` +
          `"gold-8": "${goldScale[8]}",\n` +
          `"gold-9": "${goldScale[9]}",\n` +
          `"green-0": "${greenScale[0]}",\n` +
          `"green-1": "${greenScale[1]}",\n` +
          `"green-2": "${greenScale[2]}",\n` +
          `"green-3": "${greenScale[3]}",\n` +
          `"green-4": "${greenScale[4]}",\n` +
          `"green-5": "${greenScale[5]}",\n` +
          `"green-6": "${greenScale[6]}",\n` +
          `"green-7": "${greenScale[7]}",\n` +
          `"green-8": "${greenScale[8]}",\n` +
          `"green-9": "${greenScale[9]}",\n` +
          `"teal-0": "${tealScale[0]}",\n` +
          `"teal-1": "${tealScale[1]}",\n` +
          `"teal-2": "${tealScale[2]}",\n` +
          `"teal-3": "${tealScale[3]}",\n` +
          `"teal-4": "${tealScale[4]}",\n` +
          `"teal-5": "${tealScale[5]}",\n` +
          `"teal-6": "${tealScale[6]}",\n` +
          `"teal-7": "${tealScale[7]}",\n` +
          `"teal-8": "${tealScale[8]}",\n` +
          `"teal-9": "${tealScale[9]}",\n` +
          `"blue-0": "${blueScale[0]}",\n` +
          `"blue-1": "${blueScale[1]}",\n` +
          `"blue-2": "${blueScale[2]}",\n` +
          `"blue-3": "${blueScale[3]}",\n` +
          `"blue-4": "${blueScale[4]}",\n` +
          `"blue-5": "${blueScale[5]}",\n` +
          `"blue-6": "${blueScale[6]}",\n` +
          `"blue-7": "${blueScale[7]}",\n` +
          `"blue-8": "${blueScale[8]}",\n` +
          `"blue-9": "${blueScale[9]}",\n` +
          `"indigo-0": "${indigoScale[0]}",\n` +
          `"indigo-1": "${indigoScale[1]}",\n` +
          `"indigo-2": "${indigoScale[2]}",\n` +
          `"indigo-3": "${indigoScale[3]}",\n` +
          `"indigo-4": "${indigoScale[4]}",\n` +
          `"indigo-5": "${indigoScale[5]}",\n` +
          `"indigo-6": "${indigoScale[6]}",\n` +
          `"indigo-7": "${indigoScale[7]}",\n` +
          `"indigo-8": "${indigoScale[8]}",\n` +
          `"indigo-9": "${indigoScale[9]}",\n` +
          `"violet-0": "${violetScale[0]}",\n` +
          `"violet-1": "${violetScale[1]}",\n` +
          `"violet-2": "${violetScale[2]}",\n` +
          `"violet-3": "${violetScale[3]}",\n` +
          `"violet-4": "${violetScale[4]}",\n` +
          `"violet-5": "${violetScale[5]}",\n` +
          `"violet-6": "${violetScale[6]}",\n` +
          `"violet-7": "${violetScale[7]}",\n` +
          `"violet-8": "${violetScale[8]}",\n` +
          `"violet-9": "${violetScale[9]}",\n` +
          `"gray-0": "${grayScale[0]}",\n` +
          `"gray-1": "${grayScale[1]}",\n` +
          `"gray-2": "${grayScale[2]}",\n` +
          `"gray-3": "${grayScale[3]}",\n` +
          `"gray-4": "${grayScale[4]}",\n` +
          `"gray-5": "${grayScale[5]}",\n` +
          `"gray-6": "${grayScale[6]}",\n` +
          `"gray-7": "${grayScale[7]}",\n` +
          `"gray-8": "${grayScale[8]}",\n` +
          `"gray-9": "${grayScale[9]}"\n` +
          `},\n` +
          `"typography": { "measure": [30,34,20] },\n` +
          `"tables": { "striped": ["blue-9", "gray-9", "gray-8" ], "stripe": ["light", "dark"] },\n` +
          `"borderWidths": [ 0, 0.125, 0.25, 0.5, 1, 2],\n` +
          `"borderRadius": [ 0, ${value.borderRadius1 /
            100}, ${value.borderRadius2 / 100}, ${value.borderRadius3 /
            100}, ${value.borderRadius4 / 100} ],\n` +
          `"widths": [1, 2, 4, 8, 16],\n` +
          `"heights": [1, 2, 4, 8, 16],\n` +
          `"maxWidths": [1, 2, 4, 8, 16, 32, 48, 64, 96],\n` +
          `"opacity": [1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1, 0.05, 0.025, 0]\n` +
          '}'
        }
      />
      <P fontSize={0} mt={3}>
        For use with the{' '}
        <a href="https://github.com/tachyons-css/tachyons-generator">
          Tachyons Generator.
        </a>{' '}
        Save the above as config.json and run the command below.
      </P>
      <Code fontSize={0} style={{ fontFamily: 'SFMono, monospace' }}>
        curl -X POST -H "Content-Type: application/json" -d @config.json
        https://tachyons.pub/api
      </Code>
    </>
  )
}

export default ({ initialDiv, initialPins }) => {
  let votes = []
  try {
    votes = JSON.parse(localStorage.getItem('textImageVotes')) || []
  } catch (e) {}

  const [div, setDiv] = useState(initialDiv || randomDiv())
  const [pins, setPins] = useState(initialPins || {})
  const [gridView, setGridView] = useState(false)

  const divWithPins = { ...div, ...pins }
  const fullDiv = {
    ...divWithPins
  }

  const upvote = async () => {
    if (gridView) {
      return
    }

    const { id } = await db
      .collection('components')
      .doc('textImages')
      .collection('votes')
      .add({
        up: true,
        data: fullDiv,
        metadata: { pins }
      })

    localStorage.setItem(
      'textImagesVotes',
      JSON.stringify(
        [
          {
            id
            //backgroundColor: fullGradient.backgroundColor,
            //backgroundImage: fullGradient.backgroundImage
          },
          ...votes
        ]
          .slice(0, 100)
          .filter(Boolean)
      )
    )

    setDiv(randomDiv())
  }
  const skip = () => {
    setDiv(randomDiv())
  }
  const toggleGridView = () => {
    setGridView(!gridView)
  }

  const handleVoteKey = e => {
    if (e.keyCode === UP_KEYCODE || e.keyCode === RIGHT_KEYCODE) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      upvote()
    } else if (e.keyCode === RIGHT_KEYCODE) {
      skip()
    }
  }

  const handleGridSelection = div => {
    setDiv(div)
    setGridView(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return (
    <Layout
      main={
        gridView ? (
          <GridView pins={pins} onSelect={handleGridSelection} />
        ) : (
          <Main div={fullDiv} />
        )
      }
      sidebar={
        <Sidebar
          value={fullDiv}
          pins={pins}
          onChange={(key, value) => {
            setDiv({
              ...div,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onPin={(key, value) => {
            if (!pins[key]) {
              setPins({
                ...pins,
                [key]: value
              })
            } else {
              const newPins = { ...pins }
              delete newPins[key]
              setPins(newPins)
            }
          }}
        />
      }
      footer={
        <>
          <div
            css={{
              color: 'black',
              textAlign: 'center',
              //padding: 20,
              display: 'flex',
              width: '100%',
              alignItems: 'center'
              //borderBottom: '1px solid rgba(0,0,0,.1)'
            }}
          >
            {false && (
              <Link
                to="/div/votes"
                css={{
                  color: 'black',
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textDecoration: 'none'
                }}
              >
                <BookOpen size={20} />
                <span css={{ paddingLeft: 10 }}>Previous likes</span>
              </Link>
            )}
          </div>
          <Flex>
            <VoteButton width="100%" title="Next" onClick={skip}>
              <ArrowRight size={20} />
              <Hidden>Next</Hidden>
            </VoteButton>
          </Flex>
        </>
      }
    />
  )
}
